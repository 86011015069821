import React, { useEffect } from "react";
import TitlebarImageList from "../../elements/MUI/TitlebarImageListMUI";
import {
  JOEY_THE_PASSION,
  KAIBA_THE_REVENGE,
  YUGI_THE_DESTINY,
} from "../../constants/jtp.constants";
import { useLoading } from "../../Provider/LoadingProvider";
import { LIST_CARD_IMAGE } from "../../constants/jtp.constant.service";

function CardList(props) {
  const { hide, show } = useLoading();

  useEffect(() => {
    const preloadImages = async () => {
      await Promise.all(
        LIST_CARD_IMAGE.map(
          (src) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = src;
              img.onload = resolve;
              img.onerror = reject;
            })
        )
      );
      hide();
    };

    preloadImages();
  }, [hide]);

  const yugiCards = YUGI_THE_DESTINY.map((name, key) => {
    const strId = String(Number(key) + 1).padStart(6, "0");
    return {
      img: `/YUGI_THE_DESTINY/${strId}_card.png`,
      title: name,
      id: strId,
      // author: "author",
    };
  });
  const kaibaCards = KAIBA_THE_REVENGE.map((name, key) => {
    const strId = String(Number(key) + 1).padStart(6, "0");
    return {
      img: `/KAIBA_THE_REVENGE/${strId}_card.png`,
      title: name,
      id: strId,
      // author: "author",
    };
  });
  // console.log("kaibaCards: ", kaibaCards);
  const joeyCards = JOEY_THE_PASSION.map((name, key) => {
    const strId = String(Number(key) + 1).padStart(6, "0");
    return {
      img: `/JOEY_THE_PASSION/${strId}_card.png`,
      title: name,
      id: strId,
      // author: "author",
    };
  });

  return (
    <div className="flex flex-1 bg-white h-screen w-screen flex-col">
      <TitlebarImageList
        header="Yugi the Destiny"
        {...{ itemData: yugiCards }}
      />
      <TitlebarImageList
        header="Kaiba the Revenge"
        {...{ itemData: kaibaCards }}
      />
      <TitlebarImageList
        header="Joey the Passion"
        {...{ itemData: joeyCards }}
      />
    </div>
  );
}

export default CardList;
