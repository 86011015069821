import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  CHALLENGE_IMAGE_SERVER_URL,
  SERVER_URL_LOAD_AUDIO,
  SERVER_URL_LOAD_IMAGE,
  SERVER_URL_LOAD_VIDEO,
} from "../../configs/api";
import UploadImage from "../../iconComponents/UploadImage";
import { UPLOAD_TYPE } from "../Plan/constants";
import {
  FILE_TYPE,
  getFileTypeBaseOnFileName,
  getFileTypeBaseOnMime,
} from "../../utils/file";

const FileUploader = ({
  onFileSelectSuccess,
  onFileSelectError,
  onLoadEnd,
  uploadFileTypes = [FILE_TYPE.IMAGE],
  uploadId = "inputTag",
}) => {
  // const inputRef = useRef(null);

  // const handleReset = () => {
  //   inputRef.current.value = null;
  // };

  // useEffect(() => {
  //   inputRef.current.value = null;
  // }, []);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    console.log("file: ", file);
    const maxSizeInBytes = 10 * 1024 * 1024; // Set your desired maximum file size (e.g., 10 MB)
    const fileType = getFileTypeBaseOnFileName(file?.name);
    if (fileType === FILE_TYPE.VIDEO) {
      const maxVideoSizeInBytes = 500 * 1024 * 1024;
      if (file.size > maxVideoSizeInBytes) {
        onFileSelectError({ error: "File size cannot exceed more than 500MB" });
        return;
      }
    } else {
      if (file.size > maxSizeInBytes) {
        onFileSelectError({ error: "File size cannot exceed more than 10MB" });
        return;
      }
    }

    onFileSelectSuccess(file);

    // let file = fileInput.file.files[0];
    var reader = new FileReader();
    // var url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      onLoadEnd(reader.result);
    };
  };

  // uploadFileTypes;

  const acceptTypes = uploadFileTypes.reduce((tt, filetype) => {
    if (filetype === FILE_TYPE.IMAGE) {
      tt += "image/* ";
    } else if (filetype === FILE_TYPE.VIDEO) {
      tt += "video/* ";
    } else if (filetype === FILE_TYPE.AUDIO) {
      tt += "audio/* ";
    }
    return tt;
  }, "");

  return (
    <form>
      <div className="file-uploader">
        <label
          htmlFor="inputTag"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            display: "inline-block",
            padding: "6px 12px",
            cursor: "pointer",
            borderRadius: "8px",
            color: "#3F3F46",
          }}
        >
          <UploadImage width={28} height={28} />
          <input
            // ref={inputRef}
            id={uploadId}
            multiple={true}
            type="file"
            accept={acceptTypes}
            name={`file`}
            onChange={handleFileInput}
            style={{ display: "none" }}
          />
        </label>
      </div>
    </form>
  );
};

const Upload = ({
  onPreview,
  setUploadLoading,
  style = {},
  serverApi = "",
  uploadId = "inputTag",
}) => {
  const submitForm = (selectedFile) => {
    const formData = new FormData();
    const filename = selectedFile?.name || "";
    formData.append("name", filename);
    formData.append("files", selectedFile);

    setUploadLoading(true);
    const fileType = getFileTypeBaseOnMime(selectedFile?.type);

    const objApis = {
      [FILE_TYPE.VIDEO]: `${SERVER_URL_LOAD_VIDEO}/upload/video`,
      [FILE_TYPE.IMAGE]: `${SERVER_URL_LOAD_IMAGE}/upload/image`,
      [FILE_TYPE.AUDIO]: `${SERVER_URL_LOAD_AUDIO}/upload/audio`,
    };

    if (!serverApi && !objApis[fileType]) {
      console.log(
        "Don't have serverApi or don't have API upload on this file type.",
        fileType
      );
      return;
    }

    const url = serverApi || objApis[fileType];
    console.log("url: ", url);

    axios
      .post(url, formData)
      .then((res) => {
        if (!res.data.file?.name) {
          toast("Cannot get file name");
          return;
        }
        console.log(`upload file name:`, `${res.data.file?.name}`);
        onPreview({
          name: res.data.file?.name,
          type: selectedFile?.type,
          mimetype: res.data.file?.mimetype,
        });
        setUploadLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setUploadLoading(false);
      });

    // axios
    //   .post(objApis[fileType], formData)
    //   .then((res) => {
    //     if (!res.data.file?.name) {
    //       toast("Cannot get file name");
    //       return;
    //     }
    //     onPreview(res.data.file?.name);
    //     setUploadLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log("err: ", err);
    //     setUploadLoading(false);
    //   });
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: "10px",
        right: "10px",
        zIndex: 0,
        ...style,
      }}
    >
      <FileUploader
        uploadFileTypes={[FILE_TYPE.IMAGE, FILE_TYPE.AUDIO, FILE_TYPE.VIDEO]}
        onFileSelectSuccess={(file) => {
          // console.log("onFileSelectSuccess: ", file);
          submitForm(file);
        }}
        onFileSelectError={({ error }) => alert(error)}
        // onLoadEnd={(result) => {
        //   onPreview(result)
        // }}
        uploadId={uploadId}
      />
    </div>
  );
};

export default Upload;
