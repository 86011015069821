import { Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const FlipCard = ({ frontImage, backImage, flipCount, timer, description }) => {
  // console.log("timer: ", timer);
  const [flipBack, setFlipBack] = useState(false);

  const [image, setImage] = useState(backImage);

  useEffect(() => {
    setFlipBack(true);
  }, [flipCount]);

  useEffect(() => {
    setTimeout(() => {
      setFlipBack(false);
      setImage(frontImage);
    }, timer || 0);
  }, [timer, frontImage]);

  // let imgSrc = flipBack ? backImage : image;
  let imgSrc = flipBack ? backImage : image;

  return (
    <motion.div
      className="w-[66.66px] h-[96.66px] perspective-[1000px] cursor-pointer"
      initial={{ rotateY: 180, opacity: 0 }} // Bắt đầu với mặt sau
      animate={{ rotateY: flipBack ? 180 : 0, opacity: 1 }} // Lật khi component load
      transition={{ duration: 0.5 }}
      onClick={() => setFlipBack(!flipBack)} // Click để lật qua lại
    >
      <img src={imgSrc} alt="Card" className="card-image" />
      {/* {!flipBack ? (
        <Tooltip title={description || "No description"} placement="top">
          <img src={imgSrc} alt="card" className="card-image" />
        </Tooltip>
      ) : (
        <img src={imgSrc} alt="Card" className="card-image" />
      )} */}
    </motion.div>
  );
};

export default FlipCard;
