import React, { useEffect, useState } from "react";
import { backCardImage, GAME } from "../../constants/jtp.constants";
import ImageCardRandom from "../../elements/MUI/ImageCardRandom";
import mergeCardList from "../../jsons/mergeCardList.json";
import CommonButton from "../basic/CommonButton";
import {
  createHex,
  createListImage,
  createLiteCard,
  getImageFromName,
  readDeckInYdc,
} from "../../services/ydc.service";
import { useLoading } from "../../Provider/LoadingProvider";
import {
  FUSIONS,
  LIST_CARD_IMAGE,
  MAIN_CARDS,
  MONSTER_CARDS,
} from "../../constants/jtp.constant.service";

const getRandomNumbers = (length, max) =>
  Array.from({ length }, () => Math.floor(Math.random() * max));

function JtpRandom(props) {
  const { hide, show } = useLoading();
  // console.log("load: ", load);

  const [deck, setDeck] = useState(null);
  const [flipCount, setFlipCount] = useState(0);
  const [hexFile, setHexFile] = useState("");
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    show();
  }, []);

  useEffect(() => {
    const preloadImages = async () => {
      await Promise.all(
        LIST_CARD_IMAGE.map(
          (src) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = src;
              img.onload = resolve;
              img.onerror = reject;
            })
        )
      );
      hide();
    };

    preloadImages();
  }, [hide]);

  const arrayBufferToHex = (buffer) => {
    return [...new Uint8Array(buffer)]
      .map((byte) => byte.toString(16).padStart(2, "0")) // Convert each byte to hex
      .join(""); // Join all hex values into a single string
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file

    if (file) {
      const reader = new FileReader();

      // Read file as text (use readAsArrayBuffer for binary files)
      reader.readAsArrayBuffer(file);

      // Handle file load
      reader.onload = async (e) => {
        const stringHex = arrayBufferToHex(e.target.result);
        console.log("e.target.result: ", stringHex);
        const deck = await loadFromHexData(stringHex);
        setDeck(deck);
        setFileContent(arrayBufferToHex(stringHex)); // Store file content in state
      };

      // Handle errors
      reader.onerror = () => {
        console.error("File could not be read!");
      };
    }
  };

  useEffect(() => {
    const aDeck = {
      mainCards: MAIN_CARDS.slice(0, 40).map((c) => ({
        ...c,
        image: backCardImage,
      })),
      secondCards: MAIN_CARDS.slice(40, 50).map((c) => ({
        ...c,
        image: backCardImage,
      })),
      extraCards: Array.from(Array(10), (it, key) => {
        return {
          ...FUSIONS[key],
          image: backCardImage,
        };
      }),
    };
    setDeck(aDeck);
  }, []);

  const clickDownload = () => {
    // console.log("clickDownload: ", hexFile);
    const byteArray = new Uint8Array(
      hexFile.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Create a link and trigger download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "random.ydc"; // Set filename with .ydc extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clickRandom = () => {
    // hide();
    console.log("clickRandom: ");
    const mainCards = getRandomNumbers(40, MAIN_CARDS.length)
      .map((id) => MAIN_CARDS[id])
      .sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      );
    console.log("mainCards: ", mainCards);
    const hexMain = createHex(mainCards);
    console.log("hexMain: ", hexMain);

    const extraCards = getRandomNumbers(10, FUSIONS.length)
      .map((index) => {
        return FUSIONS[index];
      })
      .sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      );
    // const hexExtra = createHex(extraCards);

    // 768
    const secondCards = getRandomNumbers(10, MAIN_CARDS.length)
      .map((id) => MAIN_CARDS[id])
      .sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      );
    const hexSecond = createHex(secondCards);
    // setHexFile("01fc1200eb82eb3f" + hexMain + hexExtra + hexSecond);
    console.log("hexSecond: ", hexSecond);
    console.log("hexMain: ", hexMain);
    setHexFile("01fc1200eb82eb3f" + hexMain + "0000" + hexSecond);

    const aDeck = {
      mainCards,
      secondCards,
      extraCards,
    };
    setDeck(aDeck);
    setFlipCount((fc) => fc + 1);
  };

  const loadFromHexData = async (hexData) => {
    console.log("loadFromHexData: ", hexData);
    const deckYdc = await readDeckInYdc(hexData);
    console.log("deckYdc: ", deckYdc);
    const deck = {
      mainCards: deckYdc.main.cards.map((hexYdc) => {
        return {
          image: getImageFromName(hexYdc),
        };
      }),
      secondCards: deckYdc.second.cards.map((hexYdc) => {
        return {
          image: getImageFromName(hexYdc),
        };
      }),
    };
    return deck;
  };

  const clickLoad = async () => {
    console.log("clickLoad: ", fileContent);
    if (!fileContent) {
      console.log("no fileContent");
    }
    const deck = loadFromHexData(fileContent);
    setDeck(deck);
  };

  return (
    <div className="flex flex-1 bg-white h-screen w-screen flex-col">
      <div className="flex py-2">
        {deck && (
          <ImageCardRandom
            header="Random Deck"
            {...{ deck, clickRandom, flipCount }}
          />
        )}
      </div>
      <div className="flex py-2 items-center justify-center">
        <CommonButton title="Random" onClick={clickRandom} />
        <CommonButton
          className="ml-2"
          title="Download"
          onClick={clickDownload}
        />
        {/* <CommonButton className="ml-2" title="Load" onClick={clickLoad} /> */}
      </div>
      <div className="flex flex-col items-center gap-4 p-4">
        <input type="file" onChange={handleFileUpload} className="border p-2" />
        <textarea
          value={fileContent}
          readOnly
          className="w-80 h-40 border p-2 mt-2"
          placeholder="File content will appear here..."
        />
      </div>
    </div>
  );
}

export default JtpRandom;
