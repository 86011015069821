import React, { useCallback, useEffect, useRef, useState } from "react";
import { getGasFeeTatum } from "../../apis/tatum";
import {
  getFearOfGreedCryptoIndex,
  getFearOfGreedIndex,
} from "../../apis/fearOfGreed";
import {
  HIGH_GAS_FEE,
  MARKETS,
  FANTOM_MEME,
  POLYGON_MEME,
  AVAX_MEME,
  MIX_PORTFOLIO,
  EXPERIENCE_MEME,
  EXPERIENCE_LONGTERM,
  EXPERIENCE_NEWS,
  EXPERIENCES,
  TAB,
  MISS_AIRDROPS,
  MISS_WAVE,
  PROCESS_TRADE,
  COIN_INVEST_REVIEW,
  TEST_PORTFOLIO,
  addColorEvent,
  IMAGE_DEFAULT,
  DCA_COINS,
  RETROACTIVE_TYPE,
  SITE_TYPES,
  WEB3_USEFULS,
  RETROACTIVE_TYPES,
} from "./constants";
import CommonButton from "../basic/CommonButton";
import useGoogleLoginCustom from "../../hooks/useGoogleLoginCustom";
import "./index.css";
import moment from "moment";
import Flow from "./Flow";
import WalletComponent from "./WalletComponent";
import ColorComponent from "./ColorComponent";
import { ROOT } from "./Flow/flow.constanst";
import { getAllCoins, getMarketList } from "./apis/coingecko";
import useLocalStorageTrigger from "../../hooks/useLocalStorageTrigger";
import {
  MARKET_LIST_COINGECKO,
  TOP_COINS,
  PLAN_COINS,
  TOKEN_PAIRS,
} from "./keys";
import { number } from "yup";
import MoneyFlowLog from "./MoneyFlowLog";
import Block from "./elements/Block";

import moneyFlow from "./assets/moneyflow.png";
import MoneyFlowSimple from "./assets/MoneyFlowSimple.jpg";
import MoneyFlowDOM from "./assets/MoneyFlowDOM.jpg";
import Title from "./elements/Title";
import { getTokenFirstPair } from "./apis/dexscreener";
import { BigNumber } from "bignumber.js";
import DexScreenPortfolio from "./DexScreenPortfolio";
import ListCoinComponent from "./ListCoinComponent";
import {
  cloneDeep,
  entries,
  groupBy,
  isArray,
  reverse,
  sortBy,
  upperCase,
  values,
} from "lodash";
import PlanMyPortfolio from "./PlanMyPortfolio";
import MixPortfolio from "./MixPortfolio";
import Expeariment from "./Expeariment";
import AnnualInvestment from "./AnnualInvestment";
import MonthlyInvestment from "./MonthlyInvestment";
import Ikigai from "./components/svgs/Ikigai";
import { SVG } from "@svgdotjs/svg.js";
import CalendarComponent from "./Calendar/CalendarComponent";
import AccordionMUI from "./components/AccordionMUI";
import { getRandomLightHexColor } from "../../utils/color";
import AccordionGroup from "./components/customs/AccordionGroup";
import MonthlyInvesment from "./MonthlyInvestment";
import MissAccordion from "./MissAccordion";
import Countdown from "react-countdown";
import { Counter } from "../CountDown/Counter";
import { PieChart } from "@mui/x-charts/PieChart";
import CustomizedTimeline from "./CustomizedTimeline";
import ImagePreview from "../../modules/ImagePreview";
import { setImageCenterByImageUrl } from "../../utils/image";
import { disableScroll } from "../../utils/browserAPI";
import ResponsiveDialog from "./components/DialogMUI";
import WrapDialogMUI from "./components/WrapDialogMUI";
import { Button } from "@mui/material";
import { Timeline } from "@mui/lab/Timeline";
import { pgPage } from "../../assets/images";
import axios from "axios";
import InputFileUpload from "../../elements/MUI/UploadFileMUI";
import { SERVER, SERVER_URL, SERVER_URL_DEV } from "../../configs/api";
import UploadFileFB from "../../elements/Flowbite/UploadFileFB";
import Portfolio from "./Portfolio";
import PortfolioComponent from "./PortfolioComponent";
import MarketListTracking from "./MarketListTracking";
import CandleStickChartBTC from "./CandleStickChartBTC";
import * as Lucide from "lucide-react";
import AudioPlayer from "./Player";
import TimelineModalComponent from "./TimelineModalComponent";
// import CandleStickChartBTC from "./CandleStickChartBTC";
// import AudioPlayer from './Player/index';
import { toast } from "react-toastify";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import ButtonMUI from "./components/ButtonMUI";
import { IoCloseCircle } from "react-icons/io5";
import {
  extractTimeString,
  extractTimeStringHarvestMoon,
  extractTimeStringXeggex,
  sortDatetimeByProperty,
} from "../../utils/datetime";
import CarouselMUI from "../../elements/MUI/CarouselMUI";
import { getAllEvent } from "../../apis/crypto/event";
import Bubble from "./Bubble";
import companies from "./Bubble/companies";
import CompanyBubble from "./Bubble/CompanyBubble";
import BubbleUI from "react-bubble-ui";
import { bubbleColors } from "./Bubble/bubbleColors";
import BubbleComponent from "./Bubble/BubbleComponent";
import BubbleChart from "@weknow/react-bubble-chart-d3";
import useScreenSize from "use-screen-size";
import {
  calculateAverageBuyingPrice,
  fix,
  getPercentBetween2Price,
  getPriceDecreaseAtNow,
  getPriceIncreaseAtNow,
} from "../../utils/price";
import { getOHLCVBinance } from "./apis/chart";
import { formatNumberDigit } from "../../utils/number";
import { deleteWeb, getAllWeb } from "../../apis/plan/web";
import CryptoEvent from "./PlanComponent/CryptoEvent";
import { getPriceApi, getPriceManyApi } from "../../apis/crypto/coin";
import { cacheFunction } from "../../helpers/cache.simple";
import MoneyFlow from "./PlanComponent/MoneyFlow";
import { getDomainFromUrl } from "../../utils/url";
import { SpaRounded } from "@material-ui/icons";
import useInterval from "../../hooks/useInterval";
import AutomationReport from "./AutomationReport";
import RequestAutomation from "./RequestAutomation";
// import { CovalentClient } from "@covalenthq/client-sdk";
import Description from "../Challenge/Edit/DescriptionCard";
import ListWeb from "./ListWeb";
import Event from "./PlanComponent/Event";
import Upload from "../Challenge/Upload";
import { getFileTypeBaseOnMime } from "../../utils/file";
import { useLoading } from "../../Provider/LoadingProvider";

function Plan() {
  // const { loginGoogle, users, getUser, removeUser, activeUser } =
  //   useGoogleLoginCustom();
  // console.log("activeUser: ", activeUser);

  const { user: activeUser } = useLoginGoogle();
  const uid = activeUser?.uid;

  const nowTriggerDate = moment().format("DD-MM-YYYY"); // update everyday

  // get market list
  const getMarketListCb = useCallback(getMarketList, []);
  const [marketList = [], updateMarketList] = useLocalStorageTrigger(
    MARKET_LIST_COINGECKO,
    0,
    undefined,
    getMarketListCb,
    nowTriggerDate
  );

  const [open, setOpen] = useState(false);
  const [selectItem, setSelectItem] = useState(null);

  const STABLECOIN_ID = ["tether", "usd-coin", "dai"];
  const WRAP_COIN_ID = ["wrapped-bitcoin", "staked-ether"];
  const excludes = [...STABLECOIN_ID, ...WRAP_COIN_ID];
  const filterMarketList = marketList.slice(0, 25).filter((it) => {
    return !excludes.includes(it?.id);
  });
  // console.log("filterMarketList: ", filterMarketList);
  const COIN_TOP = filterMarketList.map((it) => it?.id);
  // console.log("COIN_TOP: ", COIN_TOP);

  const [timeline, setTimeline] = useState(null);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (page > 0) {
      const DISPLAY_PAGE_SIZE = 10;
      const re = reverse(cloneDeep(COIN_INVEST_REVIEW));
      const array = sortDatetimeByProperty(re, "datetime");
      setTimeline(array.slice(0, DISPLAY_PAGE_SIZE * page));
    }
  }, [page]);

  const bubbleData = [
    {
      symbol: "MMM",
      name: "3M Company",
      image:
        "https://raw.githubusercontent.com/blakesanie/React-Bubble-UI/358e3228d0d30b3164cb7310f7700b87cc733a1d/example/public/companyLogos/AAPL.svg",
    },
  ];

  const size = useScreenSize();
  const bubbleClick = (label) => {
    console.log("Custom bubble click func");
  };
  const legendClick = (label) => {
    console.log("Customer legend click func");
  };

  const TopGroup = () => {
    return <></>;
  };

  const [area, setArea] = useState("");
  const [axioArea, setAxioArea] = useState("");

  const changeTextArea = async (e) => {
    console.log("changeTextArea: ", e.target.value);
    const arr = e.target.value.split("\n");
    console.log("arr: ", arr);
    // let obj = {};
    let req = {
      url: "",
      type: "",
      headers: {},
    };
    for (const pair of arr) {
      if (pair.includes("GET")) {
        const regexStr = pair.match(/GET\s(.+)\s/i);
        req.url = regexStr[1];
        req.type = "get";
      } else if (pair.includes("POST")) {
        const regexStr = pair.match(/POST\s(.+)\s/i);
        req.url = regexStr[1];
        req.type = "post";
      } else if (pair.includes(":")) {
        const sp = pair.split(":");
        const left = sp[0]?.trim();
        const right = sp[1]?.trim();
        req.headers[left] = right;
      }
    }
    console.log("req.headers: ", req.headers);
    setArea(JSON.stringify(req.headers, null, 4));

    let str = "";
    str += `const response = await axios['${req.type}'](\n`;
    str += `  '${req?.url}',\n`;
    str += `  {\n`;
    str += `    headers: {\n`;
    for (let header of Object.entries(req.headers)) {
      const [key, value] = header;
      str += `      "${key}": "${value}",\n`;
    }
    str += `    }\n`;
    str += `  }\n`;
    str += `);\n`;
    str += `console.log('response: ', response?.data);`;
    setAxioArea(str);
  };

  const [authUrl, setAuthUrl] = useState("");
  const [list, setList] = useState();

  const convertToTransparancy = async (data) => {
    try {
      console.log("convertToTransparancy: ", data);
      show();
      const resTransparency = await axios.post(
        `${SERVER_URL}/image/process/convertPartToTransparency`,
        data
      );
      console.log("resTransparency: ", resTransparency?.data);
      const resData = resTransparency?.data;
      const url = `${SERVER_URL}/${resData?.data?.image?.name}`;
      console.log("url: ", url);
      const res = {
        url,
        name: resData?.data?.image?.name,
      };
      hide();
      return res;
    } catch (error) {
      console.log("error: ", error);
      hide();
    }
  };

  const clickMinifyImage = async (data) => {
    try {
      console.log("minifyImage: ", data);
      show();
      const resTransparency = await axios.post(
        `${SERVER_URL}/image/process/minifyImage`,
        data
      );
      console.log("minifyImage: ", resTransparency?.data);
      const resData = resTransparency?.data;
      const url = `${SERVER_URL}/${resData?.data?.image?.name}`;
      console.log("url: ", url);
      const res = {
        url,
        name: resData?.data?.image?.name,
      };
      hide();
      return res;
      // setMinifyImage(res);
    } catch (error) {
      console.log("error: ", error);
      hide();
    }
  };

  const handleDownload = async ({ url, name }) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      const originalName = name.split("___")[0];
      link.download = originalName;
      // link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const { hide, show } = useLoading();

  const uploadTemp = async (event) => {
    console.log("event: ", event);
    const selectedFile = event.target.files[0];
    // console.log("selectedFile: ", selectedFile);
    const formData = new FormData();
    const filename = selectedFile?.name || "";
    // console.log("filename: ", filename);
    formData.append("name", filename);
    formData.append("files", selectedFile);
    show();

    const url = `${SERVER_URL}/upload/temp`;
    console.log("url: ", url);
    try {
      const res = await axios.post(url, formData);
      if (!res.data.file?.name) {
        toast("Cannot get file name");
        return;
      }

      console.log(`upload file name:`, `${res.data.file?.name}`);
      const file = {
        name: res.data.file?.name,
        type: selectedFile?.type,
        mimetype: res.data.file?.mimetype,
      };
      hide();
      return file;
    } catch (error) {
      console.log("error: ", error);
      hide();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
    >
      <AccordionGroup titleGroup={`TEST AREA`} defaultShow={false}>
        {/* <ButtonMUI
          label="Auth"
          onClick={async () => {
            console.log("skjfksjdfklsjdfl");
            const k = await axios.get(`${SERVER_URL}/auth-google/auth-task`);
            console.log("k: ", k);
            setAuthUrl(k?.data?.authUrl);
          }}
        />
        {authUrl && <a href={authUrl}>Auth Link</a>} */}

        {/* {["nguyenquanit17@gmail.com", "romanticprince1603@gmail.com"].map(
          (email) => {
            return (
              <ButtonMUI
                label={`List File ${email.slice(0, 5)}`}
                onClick={async () => {
                  const listFile = await axios.post(
                    `${SERVER_URL}/auth-google/list-file`,
                    {
                      email,
                    }
                  );
                  console.log("listFile: ", listFile);
                  setList(listFile?.data?.files);
                }}
              />
            );
          }
        )}
        <ButtonMUI
          label="Upload Test"
          onClick={async () => {
            const uploadFile = await axios.post(
              `${SERVER_URL}/auth-google/upload-file`
            );
            console.log("uploadFile: ", uploadFile);
            // setList(uploadFile?.data?.files);
          }}
        /> */}
        {/* {list &&
          list?.map((it) => {
            return (
              <div
                className="flex flex-row cursor-pointer"
                onClick={async () => {
                  const dl = await axios.post(
                    `${SERVER_URL}/auth-google/download-file`,
                    {
                      id: it?.id,
                      name: it?.name,
                      email: "nguyenquanit17@gmail.com",
                    }
                  );
                  console.log("dl: ", dl?.data);
                }}
              >
                <div>{`${it?.id} - ${it?.name}`}</div>
              </div>
            );
          })} */}
      </AccordionGroup>
      <AccordionGroup titleGroup={`IMAGE TOOLS`} defaultShow={false}>
        <div className="flex flex-row justify-evenly m-2">
          <InputFileUpload
            title="Upload remove right"
            onChange={async (event) => {
              const file = await uploadTemp(event);
              const res = await convertToTransparancy(file);
              handleDownload(res);
            }}
          />
          <InputFileUpload
            title="Upload minify"
            onChange={async (event) => {
              const file = await uploadTemp(event);
              const res = await clickMinifyImage(file);
              handleDownload(res);
            }}
          />
        </div>
      </AccordionGroup>
      {/* <AccordionGroup titleGroup={`TEST AREA`} defaultShow={false}>
        <label
          htmlFor="message"
          className="m-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Your message
        </label>
        <textarea
          id="message"
          rows="6"
          className="m-2 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Write your thoughts here..."
          onChange={changeTextArea}
        />
        <textarea
          id="message"
          rows="8"
          className="m-2 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Write your thoughts here..."
          value={area}
        />
        <textarea
          id="message"
          rows="16"
          className="m-2 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Write your thoughts here..."
          value={axioArea}
        />
      </AccordionGroup> */}

      <TopGroup />
      {/* <AccordionGroup titleGroup={`AUTOMATION REPORT`} defaultShow={true}>
        <RequestAutomation />
        <AutomationReport />
      </AccordionGroup> */}

      {/* <div className="flex">
        {arrImage.map((image, key) => {
          //
          return (
            <div key={key} className="bg-red-400 m-2 relative w-60 h-60">
              <img
                className="absolute w-60 h-60 top-1/2 transform -translate-y-1/2 object-cover"
                src={image}
                alt="alt img"
              />
            </div>
          );
        })}
      </div> */}

      <AccordionGroup titleGroup={`INVEST PROCESS EVENT`} defaultShow={true}>
        <Event type="CRYPTO_INVESTMENT_PROCESS" />
      </AccordionGroup>

      {/* <AccordionGroup titleGroup={`CRYPTO EVENT`} defaultShow={true}>
        <CryptoEvent />
      </AccordionGroup> */}

      {/* <AccordionGroup titleGroup={`CALENDAR`} defaultShow={true}>
        <CalendarComponent />
      </AccordionGroup> */}
      {/* <AccordionGroup titleGroup={`CRYPTO NEWS IN MONTH`}>
        <CustomizedTimeline
          title=""
          data={reverse(cloneDeep(PROCESS_TRADE))}
          clickTimelineDot={async (item) => {
            if (!item?.image) return;
            console.log("clickTimelineDot: ", item);
            setOpen(!open);
            setSelectItem(item);
          }}
        />
      </AccordionGroup> */}
      {/* <AccordionGroup titleGroup={`MONEY FLOW`} defaultShow={false}>
        <MoneyFlow month={4} />
      </AccordionGroup> */}
      {/* <BubbleChart
        graph={{
          zoom: 1,
        }}
        width={size.width}
        height={1300}
        padding={0} // optional value, number that set the padding between bubbles
        showLegend={false} // optional value, pass false to disable the legend.
        legendPercentage={20} // number that represent the % of with that legend going to use.
        legendFont={{
          family: "Arial",
          size: 12,
          color: "#000",
          weight: "bold",
        }}
        valueFont={{
          family: "Arial",
          size: 12,
          color: "#fff",
          weight: "bold",
        }}
        labelFont={{
          family: "Arial",
          size: 16,
          color: "#fff",
          weight: "bold",
        }}
        bubbleClickFunc={bubbleClick()}
        legendClickFun={legendClick()}
        data={[
          { label: "CRM", value: 1 },
          { label: "API", value: 1 },
          { label: "Data", value: 1 },
          { label: "Commerce", value: 1 },
          { label: "AI", value: 3 },
          { label: "Management", value: 5 },
          { label: "Testing", value: 6 },
          { label: "Mobile", value: 9 },
          { label: "Conversion", value: 9 },
          { label: "Misc", value: 21 },
          { label: "Databases", value: 22 },
          { label: "DevOps", value: 22 },
          { label: "Javascript", value: 23 },
          { label: "Languages / Frameworks", value: 25 },
          { label: "Front End", value: "26" },
          { label: "Content", value: 26 },
        ]}
      /> */}
      {/* <BubbleUI
        className="bubbleUI"
        options={{
          size: 180,
          minSize: 20,
          gutter: 8,
          provideProps: true,
          numCols: 6,
          fringeWidth: 160,
          yRadius: 130,
          xRadius: 220,
          cornerRadius: 50,
          showGuides: false,
          compact: true,
          gravitation: 5,
        }}
      >
        {bubbleData.map((bubble, i) => {
          const { backgroundColor, textColor } = bubbleColors[i] || {};
          const { bubbleSize, symbol, name } = bubble || {};
          return (
            <BubbleComponent
              key={i}
              {...{
                bubbleSize,
                symbol,
                name,
                backgroundColor,
                textColor,
              }}
            />
          );
        })}
      </BubbleUI> */}

      {/* <Block>
        <CarouselMUI />
      </Block> */}
      {/* <AccordionGroup titleGroup={`MUSIC AREA`} defaultShow={true}>
        <AudioPlayer />
      </AccordionGroup> */}
      <AccordionGroup titleGroup={`BTC CHART`} defaultShow={false}>
        <CandleStickChartBTC {...{ nowTriggerDate }} />
      </AccordionGroup>
      {/* <MarketListTracking
        {...{
          title: "MARKET TOP MARKETCAP",
          nowTriggerDate,
          marketList: filterMarketList,
          updateMarketList,
        }}
      /> */}
      {/* <AccordionGroup titleGroup={`TEST PORTFOLIO`} defaultShow={true}>
        <MixPortfolio
          title="TEST PORTFOLIO"
          list={TEST_PORTFOLIO}
          name={"TEST_PORTFOLIO"}
          {...{ nowTriggerDate }}
        />
      </AccordionGroup> */}
      {/* <PortfolioComponent /> */}
      {/* <AccordionGroup titleGroup={`PORTFOLIO`} defaultShow={true}>
        <Portfolio
          title="PORTFOLIO"
          list={MIX_PORTFOLIO}
          storage="PORTFOLIO"
          {...{ nowTriggerDate }}
        />
      </AccordionGroup> */}
      {/* <GoogleSheets /> */}
      {/* <CommonButton title="AUTH GOOGLE" onClick={() => loginGoogle()} /> */}
      {/* <UserListComponent {...{ users }} /> */}
      {/* {timeline && (
        <AccordionGroup titleGroup={`COIN INVEST REVIEW`} defaultShow={true}>
          <CustomizedTimeline
            title=""
            data={timeline}
            clickTimelineDot={async (item) => {
              if (!item?.image) return;
              console.log("clickTimelineDot: ", item);
              setOpen(!open);
              setSelectItem(item);
            }}
          />
          <Block style={{ flexDirection: "column" }}>
            <Button onClick={() => setPage(page + 1)}>Load more</Button>
            <Button onClick={() => setPage(page - 1)}>Load less</Button>
          </Block>
        </AccordionGroup>
      )} */}

      {/* <WrapDialogMUI {...{ open, setOpen }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {isArray(selectItem?.image) ? (
            selectItem?.image.map((img, index) => (
              <img
                style={{ padding: 8 }}
                key={index}
                src={img}
                alt={`Trade ${index}`}
              />
            ))
          ) : (
            <img src={selectItem?.image} alt="Trade" />
          )}
        </div>
      </WrapDialogMUI> */}

      {/* <AccordionGroup titleGroup={`IKIGAI`}>
        <Block>
          <img src={require("./assets/ikigai-vn.png")} alt="ikigai" />
        </Block>
      </AccordionGroup> */}

      {/* <ColorComponent /> */}
      {/* <AccordionGroup titleGroup={`VIỆC MỖI NGÀY`}>
        <div className="bg-pink-200 m-4">
          <div className="text-2xl font-bold text-center p-1">
            VIỆC MỖI NGÀY
          </div>
          <ol className="">
            <li>
              - Kiểm tra dòng tiền xem hiện tại đang trong giai đoạn nào{" "}
              <a href="https://coinmarketcap.com/">coinmarketcap</a>
              <img src={moneyFlow} alt="Money flow" />
            </li>
            <li>- Đón đầu trước dòng tiền</li>
            <li>- + Tìm những coin mua trước</li>
            <li>{`- Kiểm tra gas fee => Làm theo gas fee plan `}</li>
            <li>{`-- Gasfee tăng -> research coin, trade`}</li>
            <li>{`-- Gasfee giảm -> rretroactive`}</li>
            <li>{`1. Không chơi các loại sau`}</li>
            <li>{`---- Retroactive sử dụng ref`}</li>
            <li>{`---- Mua Bán NFT -> Thanh khoản thấp + cần time nghiên cứu NFT`}</li>
            <li>{`---- GameFi -> Phải nghiên cứu game + mua NFT`}</li>
            <li>{`---- BRC-20 -> Giá quá mắc so với khả năng`}</li>
            <li>{`---- BRC-20 MEME -> Thanh khoản thấp`}</li>
            <li>{`---- ERC 404 -> Rủi ro cao, giá khá chát`}</li>
            <li>{`2. Tìm hiểu thêm`}</li>
            <li>{`---- Kiểm tra dòng tiền trong crypto`}</li>
            <li>{`---- Trade coin no Future`}</li>
            <li>{`---- Tìm hiểu POW coin (400$)`}</li>
            <li>{`---- Tìm hiểu DRC-20 (150$)`}</li>
            <li>{`---- Tìm hiểu MEME (FTM - 200$, AVAX - 25$, MATIC - 25$)`}</li>
          </ol>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          ></div>
        </div>
      </AccordionGroup> */}

      {/* <AnnualInvestment /> */}
      {/* <MonthlyInvestment /> */}
      {/* <AccordionGroup titleGroup={`MIX PORTFOLIO`} defaultShow={true}>
        <MixPortfolio
          title="MIX PORTFOLIO (850$)"
          list={MIX_PORTFOLIO}
          name={"MIX_PORTFOLIO"}
          {...{ nowTriggerDate }}
        />
      </AccordionGroup> */}
      {/* <AccordionGroup titleGroup={`WALLETS`} defaultShow={true}>
        <WalletComponent />
      </AccordionGroup> */}
      <AccordionGroup titleGroup={`TỔNG KẾT THÁNG`}>
        <MonthlyInvestment />
      </AccordionGroup>
      {/* <DexScreenPortfolio
        title="FANTOM MEME"
        list={FANTOM_MEME}
        name={"FANTOM_MEME"}
        {...{ nowTriggerDate }}
      /> */}
      {/* <DexScreenPortfolio
        title="POLYGON MEME"
        list={POLYGON_MEME}
        name={"POLYGON_MEME"}
        {...{ nowTriggerDate }}
      />

      <DexScreenPortfolio
        title="AVAX MEME"
        list={AVAX_MEME}
        name={"AVAX_MEME"}
        {...{ nowTriggerDate }}
      /> */}
      <AccordionGroup titleGroup={`EXPERIENCES`}>
        <MissAccordion
          data={MISS_AIRDROPS}
          title="MISS AIRDROP"
          defaultShow={true}
        />
        <MissAccordion data={MISS_WAVE} title="MISS WAVE" defaultShow={true} />
        <Expeariment data={EXPERIENCE_MEME} title="MEME EXPERIENCES" />
        <Expeariment data={EXPERIENCE_LONGTERM} title="LONGTERM EXPERIENCES" />
        <Expeariment data={EXPERIENCE_NEWS} title="EXPERIENCE NEWS" />
        <Expeariment data={EXPERIENCES} title="EXPERIENCES" />
      </AccordionGroup>
      {/* <Block
        style={{
          backgroundColor: "red",
        }}
      >
        <img src={moneyFlow} alt="Money flow" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            width="50%"
            height="auto"
            src={MoneyFlowSimple}
            alt="Money flow simple"
          />
          <img
            width="50%"
            height="auto"
            src={MoneyFlowDOM}
            alt="Money flow dom"
          />
        </div>
      </Block> */}
      {/* <MoneyFlowLog /> */}
      {/* <AddToCalendarButton
        name="Title"
        options={["Apple", "Google"]}
        location="World Wide Web"
        startDate="2023-12-07"
        endDate="2023-12-07"
        startTime="10:15"
        endTime="23:30"
        timeZone="America/Los_Angeles"
      ></AddToCalendarButton> */}
      {/* <PlanMyPortfolio
        {...{
          listCoinId: COIN_TOP,
          title: "COIN TOP CHANGE",
          storageKey: TOP_COINS,
        }}
      /> */}
      {/* <PlanMyPortfolio
        {...{ listCoinId, title: "MY PORTFOLIO", storageKey: PLAN_COINS }}
      /> */}
      {/* <CallCoinComponent /> */}
      {/* <CommonButton
        title="OPEN EDGE"
        onClick={() => {
          console.log("onClick: ");
        }}
      /> */}
      {/* <CommonButton
        title="OPEN MODAL"
        onClick={() => {
          setOpenModal(true);
        }}
      /> */}
      {/* <Task {...{ gasFee, action }} /> */}
      {/* <Flow {...{ gasFee, action, root: ROOT }} /> */}
    </div>
  );
}

export default Plan;
