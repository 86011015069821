import {
  backCardImage,
  mergeCardList,
  zazaCardList,
} from "../constants/jtp.constants";
import { decToHexWithSwap, hexToDec, swapBytes } from "../utils/number";

export function createHex(cardLiteList) {
  if (!cardLiteList || cardLiteList?.length === 0) {
    return numberToHexYdc(cardLiteList.length);
  }
  const strCardHex = cardLiteList.reduce((total, lc) => {
    return total + lc.cardIdYdc;
  }, "");
  const strNum = numberToHexYdc(cardLiteList.length);
  // console.log("strNum: ", strNum);
  // console.log("strCardHex: ", strCardHex);
  return strNum + strCardHex;
}

export const numberToHexYdc = (numberDec) => {
  return decToHexWithSwap(numberDec);
};

export const hexYdcToNumber = (hexYdc) => {
  return hexToDec(hexYdc);
};
export function hexZazaToHexYdc(hex) {
  return swapBytes(hex);
}

export async function readDeckInYdc(hexData) {
  const deck = {};
  let indexNumber = 0;
  let left = hexData.substring(16, hexData.length);
  let count = 1;
  while (left?.length) {
    console.log("count: ", count);
    const hexNumber = left.substring(indexNumber, indexNumber + 4);
    console.log("hexNumber: ", hexNumber);
    const cardNumber = hexYdcToNumber(hexNumber);
    console.log("cardNumber: ", cardNumber);
    if (cardNumber > 0) {
      const maxLength = indexNumber + 4 + cardNumber * 4;
      const strDeckCards = left.substring(indexNumber + 4, maxLength);
      // console.log("strDeckCards: ", strDeckCards);
      const cards = splitStringIntoChunks(strDeckCards);
      const zazaCards = cards.map((hexId) => getNameByCardIdHex(hexId));
      console.log(
        "zazaCards: ",
        zazaCards.map((c) => c.cardName)
      );

      if (count === 1) {
        deck["main"] = {
          cards,
          number: cardNumber,
          zazaNames: zazaCards.map((zz) => zz.cardName),
        };
      } else if (count === 2) {
        deck["extra"] = {
          cards,
          number: cardNumber,
          zazaNames: zazaCards.map((zz) => zz.cardName),
        };
      } else if (count === 3) {
        deck["second"] = {
          cards,
          number: cardNumber,
          zazaNames: zazaCards.map((zz) => zz.cardName),
        };
      }
      left = left.substring(maxLength, left.length);
      console.log("left: ", left.length, left);
      if (left.length === 0) break;
      indexNumber = 0;
      count++;
    } else if (cardNumber === 0) {
      left = left.substring(4, left.length);
      if (left.length === 0) break;
      indexNumber = 0;
      count++;
    }
  }
  return deck;
}

function splitStringIntoChunks(str, chunkSize = 4) {
  const result = [];
  for (let i = 0; i < str.length; i += chunkSize) {
    result.push(str.substring(i, i + chunkSize));
  }
  return result;
}

function getNameByCardIdHex(cardIdHex) {
  const fCard = zazaCardList.find(
    (z) =>
      hexZazaToHexYdc(z.cardIdHex.toLowerCase()) === cardIdHex.toLowerCase()
  );
  if (!fCard) {
    console.log("Can not found cardIdHex", cardIdHex);
  }
  return fCard;
}

export function createListImage(mergeCardList) {
  return mergeCardList.map((c) => {
    if (!c?.game?.name) {
      return backCardImage;
    }
    const strId = String(c.game.gameCardId).padStart(6, "0");
    return `/${c?.game?.name}/${strId}_card.png`;
  });
}

export function createLiteCard(mergeCardList) {
  return mergeCardList.map((c) => {
    if (!c?.zazaCard) {
      console.log("-----c", c);
    }

    const strId = String(c.game.gameCardId).padStart(6, "0");
    return {
      id: c.game.gameCardId,
      name: c.name,
      image: `/${c.game.name}/${strId}_card.png`,
      cardIdHex: c?.zazaCard?.cardIdHex,
      cardIdYdc: hexZazaToHexYdc(c?.zazaCard?.cardIdHex),
      description: c?.description,
    };
  });
}

export function getImageFromName(hexYdc) {
  let mergeCard = mergeCardList.find(
    (mc) =>
      hexZazaToHexYdc(mc.zazaCard.cardIdHex.toLowerCase()) ===
      hexYdc.toLowerCase()
  );
  if (!mergeCard) {
    mergeCard = mergeCardList[0];
  }
  const strId = String(mergeCard.game.gameCardId).padStart(6, "0");
  const image = `/${mergeCard.game.name}/${strId}_card.png`;
  return image;
}
