import mergeCardListwZaza from "../jsons/mergeCardList.json";
export const mergeCardList = mergeCardListwZaza;
export const zazaCardList = mergeCardListwZaza.map((c) => c.zazaCard);

export const YUGI_THE_DESTINY = [
  "Ancient Elf",
  "Ansatsu",
  "Anti Raigeki",
  "Aqua Madoor",
  "Armaill",
  "Armed Ninja",
  "Armored Starfish",
  "Baron of the Fiend Sword",
  "Basic Insect",
  "Beast Fangs",
  "Beaver Warrior",
  "Blue-Eyes White Dragon",
  "Book of Secret Arts",
  "Card Destruction",
  "Castle Walls",
  "Celtic Guardian",
  "Change of Heart",
  "Charubin the Fire Knight",
  "Claw Reacher",
  "Curse of Dragon",
  "Dark Energy",
  "Dark Gray",
  "Dark Hole",
  "Dark King of the Abyss",
  "Dark Magician",
  "Darkfire Dragon",
  "Darkworld Thorns",
  "De-Spell",
  "Dian Keto the Cure Master",
  "Dissolverock",
  "Doma The Angel of Silence",
  "Dragon Capture Jar",
  "Dragon Treasure",
  "Dragon Zombie",
  "Dragoness the Wicked Knight",
  "Drooling Lizard",
  "Electro-Whip",
  "Enchanting Mermaid",
  "Exodia the Forbidden One",
  "Feral Imp",
  "Fiend Reflection #2",
  "Final Flame",
  "Firegrass",
  "Fireyarou",
  "Fissure",
  "Flame Ghost",
  "Flame Manipulator",
  "Flower Wolf",
  "Follow Wind",
  "Forest",
  "Frenzied Panda",
  "Fusionist",
  "Gaia the Dragon Champion",
  "Gaia The Fierce Knight",
  "Giant Soldier of Stone",
  "Goblin's Secret Remedy",
  "Graceful Charity",
  "Great White",
  "Green Phantom King",
  "Hane-Hane",
  "Hard Armor",
  "Hinotama",
  "Hinotama Soul",
  "Hitotsu-Me Giant",
  "Kagemusha of the Blue Flame",
  "Karbonala Warrior",
  "King Fog",
  "Kumootoko",
  "Kurama",
  "Kuriboh",
  "Larvas",
  "Laser Cannon Armor",
  "Left Arm of the Forbidden One",
  "Left Leg of the Forbidden One",
  "Legendary Sword",
  "Lesser Dragon",
  "Machine Conversion Factory",
  "Magical Ghost",
  "Mammoth Graveyard",
  "Man Eater",
  "Man-Eater Bug",
  "Man-Eating Treasure Chest",
  "Masaki the Legendary Swordsman",
  "Meda Bat",
  "Metal Dragon",
  "Mirror Force",
  "Misairuzame",
  "Monster Egg",
  "Monster Reborn",
  "Mountain",
  "M-Warrior #1",
  "M-Warrior #2",
  "Mystic Clown",
  "Mystical Elf",
  "Mystical Moon",
  "Mystical Sheep #2",
  "Nemuriko",
  "Neo the Magic Swordsman",
  "One-Eyed Shield Dragon",
  "Petit Angel",

  "Petit Dragon",
  "Polymerization",
  "Pot of Greed",
  "Power of Kaishin",
  "Raigeki",
  "Raise Body Heat",
  "Ray & Temperature",
  "Reaper of the Cards",
  "Red Medicine",
  "Reinforcements",
  "Remove Trap",
  "Reverse Trap",
  "Right Arm of the Forbidden One",
  "Right Leg of the Forbidden One",
  "Root Water",
  "Sand Stone",
  "Silver Bow and Arrow",
  "Silver Fang",
  "Skull Red Bird",
  "Skull Servant",
  "Sogen",
  "Sorcerer of the Doomed",
  "Sparks",
  "Spellbinding Circle",
  "Spike Seadra",
  "Spirit of the Harp",
  "Steel Ogre Grotto #1",
  "Stop Defense",
  "Succubus Knight",
  "Summoned Skull",

  "Sword of Dark Destruction",
  "Swords of Revealing Light",
  "Terra the Terrible",
  "The 13th Grave",
  "The Furious Sea King",
  "The Stern Mystic",
  "Trap Hole",
  "Trap Master",
  "Trial of Nightmare",
  "Tri-Horned Dragon",
  "Tripwire Beast",
  "Turtle Tiger",
  "Two-Mouth Darkruler",
  "Two-Pronged Attack",
  "Tyhone",
  "Umi",
  "Uraby",
  "Vile Germs",
  "Violet Crystal",
  "Waboku",
  "Wall of Illusion",
  "Wasteland",
  "Winged Dragon, Guardian of the Fortress #1",
  "Witty Phantom",
  "Yami",
];

export const backCardImage = `/back.png`;

export const GAME = {
  YUGI_THE_DESTINY: "YUGI_THE_DESTINY",
  KAIBA_THE_REVENGE: "KAIBA_THE_REVENGE",
  JOEY_THE_PASSION: "JOEY_THE_PASSION",
};

export const KAIBA_THE_REVENGE = [
  "7 Colored Fish",
  "Acid Trap Hole",
  "Ameba",
  "Ancient Lamp",
  "Ancient Lizard Warrior",
  "Ancient One of the Deep Forest",
  "Anti-Spell Fragrance",
  "Armored Lizard",
  "Armored Zombie",
  "Axe of Despair",
  "Baby Dragon",
  "Barrel Dragon",
  "Battle Ox",
  "Battle Steer",
  "Bean Soldier",
  "Beastking of the Swamps",
  "Beautiful Headhuntress",
  "Bickuribox",
  "Big Eye",
  "Black Illusion Ritual",
  "Black Pendant",
  "Blackland Fire Dragon",
  "Bladefly",
  "Blast Juggler",
  "Block Attack",
  "Blue-Eyes Ultimate Dragon",
  "Blue-Eyes White Dragon",
  "Blue-Winged Crown",
  "Bottom Dweller",
  "Call of the Grave",
  "Cannon Soldier",
  "Castle of Dark Illusions",
  "Catapult Turtle",
  "Chain Energy",
  "Change of Heart",
  "Chorus of Sanctuary",
  "Cockroach Knight",
  "Cocoon of Evolution",
  "Commencement Dance",
  "Confiscation",
  "Corroding Shark",
  "Cosmo Queen",
  "Crab Turtle",
  "Crass Clown",
  "Crawling Dragon",
  "Crawling Dragon #2",
  "Crush Card Virus", //Crush Card
  "Curse of Fiend",
  "Cyber Jar",
  "Cyber Saurus",
  "Cyber Soldier of Darkworld",
  "Dancing Elf",
  "Dark Elf",
  "Dark Magician",
  "Dark Witch",
  "Darkness Approaches",
  "Dark-Piercing Light",
  "Deepsea Shark",
  "Delinquent Duo",
  "Destiny Board",
  "Destroyer Golem",
  "Dharma Cannon",
  "Disk Magician",
  "Dokurorider",
  "Dragon Piper",
  "Dragon Seeker",
  "Dream Clown",
  "Eatgaboon",
  "Electric Lizard",
  "Electric Snake",
  "Elegant Egotist",
  "Empress Judge",
  "Eternal Rest",
  "Exchange",
  "Exile of the Wicked",
  "Fairy's Hand Mirror",
  "Faith Bird",
  "Fake Trap",
  "Final Destiny",
  "Fire Kraken",
  "Flying Kamakiri #1",
  "Flying Penguin",
  "Slime Toad", //"Frog the Jam", //
  "Gadget Soldier",
  "Gaia Power",
  "Garoozis",
  "Gate Guardian",
  "Gazelle the King of Mythical Beasts",
  "Germ Infection",
  "Giant Flea",
  "Giant Germ",
  "Giant Rat",
  "Giant Red Seasnake",
  "Giant Trunade",
  "Giant Turtle Who Feeds on Flames",
  "Giga-Tech Wolf",
  "Giltia the D. Knight",
  "Goblin Fan",
  "Goddess of Whim",
  "Goddess with the Third Eye",
  "Gravedigger Ghoul",
  "Gravekeeper's Servant",
  "Great Moth",
  "Griggle",
  "Guardian of the Labyrinth",
  "Guardian of the Throne Room",
  "Hamburger Recipe",
  "Harpie Lady",
  "Harpie Lady Sisters",
  "Harpie's Feather Duster",
  "Heavy Storm",
  "Hercules Beetle",
  "Hibikime",
  "High Tide Gyojin",
  "Hiro's Shadow Scout",
  "Horn of Heaven",
  "Horn of Light",
  "Horn of the Unicorn",
  "Hoshiningen",
  "House of Adhesive Tape",
  "Hungry Burger",
  "Hunter Spider",
  "Hyosube",
  "Hyozanryu",
  "Illusionist Faceless Mage",
  "Imperial Order",
  "Invader of the Throne",
  "Invitation to a Dark Sleep",
  "Jellyfish",
  "Jigen Bakudan",
  "Jinzo #7",
  "Jirai Gumo",
  "Judge Man",
  "Kazejin",
  "Killer Needle",
  "King of Yamimakai",
  "Kojikocy",
  "Kuriboh",
  "Kuwagata α",
  "Kwagar Hercules",
  "La Jinn the Mystical Genie of the Lamp",
  "Labyrinth Tank",
  "Labyrinth Wall",
  "Lady of Faith",
  "Larvae Moth",
  "Launcher Spider",
  "Lava Battleguard",
  "Leghul",
  "Little Chimera",
  "Luminous Spark",
  "Magic Jammer",
  "Magician of Faith",
  "Maha Vailo",
  "Maiden of the Moonlight",
  "Malevolent Nuzzler",
  "Mask of Darkness",
  "Masked Sorcerer",
  "Mechanical Snail",
  "Mechanicalchaser",
  "Megamorph",
  "Megasonic Eye",
  "Mesmeric Control",
  "Metal Fish",
  "Metalmorph",
  "Metalzoa",
  "Mikazukinoyaiba",
  "Millennium Shield",
  "Milus Radiant",
  "Minar",
  "Molten Destruction",
  "Monster Reborn",
  "Monster Recovery",
  "Morphing Jar",
  "Mother Grizzly",
  "Muka Muka",
  "Mushroom Man #2",
  "Musician King",
  "Mystic Horseman",
  "Mystic Plasma Zone",
  "Mystic Tomato",
  "Mystical Sheep #1",
  "Mystical Space Typhoon",
  "Needle Worm",
  "Negate Attack",
  "Nimble Momonga",
  "Niwatori",
  "Nobleman of Crossout",
  "Nobleman of Extermination",
  "Novox's Prayer",
  "Ooguchi",
  "Oscillo Hero",
  "Wattkid",
  "Painful Choice",
  "Pale Beast",
  "Paralyzing Potion",
  "Parrot Dragon",
  "Peacock",
  "Penguin Knight",
  "Performance of Sword",
  "Petit Moth",
  "Polymerization",
  "Premature Burial",
  "Prevent Rat",
  "Princess of Tsurugi",
  "Protector of the Throne",
  "Pumpking the King of Ghosts",
  "Punished Eagle",
  "Queen Bird",
  "Queen of Autumn Leaves",
  "Queen's Double",
  "Rabid Horseman",
  "Rainbow Flower",
  "Red-Eyes Black Dragon",
  "Red-Eyes Black Metal Dragon",
  "Relinquished",
  "Revival of Dokurorider",
  "Ring of Destruction",
  "Ring of Magnetism",
  "Rising Air Current",
  "Roaring Ocean Snake",
  "Robbin' Goblin",
  "Rock Ogre Grotto #1",
  "Royal Decree",
  "Rush Recklessly",
  "Ryu-Kishin Powered",
  "Ryu-Ran",
  "Saggi the Dark Clown",
  "Sanga of the Thunder",
  "Sangan",
  "Seiyaryu",
  "Senju of the Thousand Hands",
  "Serpent Night Dragon",
  "Seven Tools of the Bandit",
  "Shadow Ghoul",
  "Share the Pain",
  "Shining Angel",
  "Shining Friendship",
  "Skull Guardian",
  "Skull Knight",
  "Sky Dragon",
  "Snake Fang",
  "Snatch Steal",
  "Solemn Judgment",
  "Sonic Bird",
  "Sonic Maid",
  "Soul Exchange",
  "Soul of the Pure",
  "Spellbinding Circle",
  'Spirit Message "A"',
  'Spirit Message "I"',
  'Spirit Message "L"',
  'Spirit Message "N"',
  "Spirit of the Books",
  "Star Boy",
  "Steel Scorpion",
  "Stim-Pack",
  "Stuffed Animal",
  "Suijin",
  "Swamp Battleguard",
  "Sword of Deep-Seated",
  "Swordstalker",
  "Tailor of the Fickle",
  "Tainted Wisdom",
  "Takriminos",
  "Takuhee",
  "The Bistro Butcher",
  "The Cheerful Coffin",
  "The Fiend Megacyber",
  "The Forceful Sentry",
  "The Judgement Hand",
  "The Little Swordsman of Aile",
  "The Reliable Guardian",
  "The Shallow Grave",
  "The Unhappy Maiden",
  "Thousand Dragon",
  "Thousand Knives",
  "Thousand-Eyes Idol",
  "Thousand-Eyes Restrict",
  "Three-Headed Geedo",
  "Three-Legged Zombies",
  "Thunder Dragon",
  "Time Wizard",
  "Toll",
  "Tongyo",
  "Tremendous Fire",
  "Trent",
  "Tribute to the Doomed",
  "Turtle Oath",
  "Turu-Purun",
  "Twin Long Rods #2",
  "Twin-Headed Thunder Dragon",
  "Two-Headed King Rex",
  "Tyhone #2",
  "UFO Turtle",
  "Umiiruka",
  "Upstart Goblin",
  "Ushi Oni",
  "Versago the Destroyer",
  "Vorse Raider",
  "Warrior of Tradition",
  "Water Magician",
  "Water Omotics",
  "Weather Report",
  "Whiptail Crow",
  "White Hole",
  "White Magical Hat",
  "Windstorm of Etaqua",
  "Winged Dragon, Guardian of the Fortress #2",
  "Witch of the Black Forest",
  "Witch's Apprentice",
  "Wodan the Resident of the Forest",
  "Yado Karu",
  "Yamadron",
  "Yaranzo",
  "Zoa",
];

export const JOEY_THE_PASSION = [
  "4-Starred Ladybug of Doom",
  "7 Completed",
  "Alligator's Sword",
  "Alligator's Sword Dragon",
  "Alpha the Magnet Warrior",
  "Amazoness Archer", //"Amazon Archer",
  "Amphibian Beast",
  "Ancient Brain",
  "Anti-Spell Fragrance",
  "Appropriate",
  "Aqua Chorus",
  "Aqua Spirit",
  "Armored Glass",
  "Attack and Receive",
  "Axe Raider",
  "Black Skull Dragon",
  "Baby Dragon",
  "Backup Soldier",
  "Bait Doll",
  "Banisher of the Light",
  "Battle Warrior",
  "Bazoo the Soul-Eater",
  "Archfiend of Gilfer", // "Beast of Gilfer",
  "Berfomet",
  "Beta the Magnet Warrior",
  "Big Shield Gardna",
  "Bio-Mage",
  "Bite Shoes",
  "Blast Sphere",
  "Blind Destruction",
  "Blue Medicine",
  "Blue-Eyes Toon Dragon",
  "Boar Soldier",
  "Bombardment Beetle",
  "Boneheimer",
  "Bubonic Vermin",
  "Burning Land",
  "Burning Spear",
  "Buster Blader",
  "Call of the Haunted",
  "Card of Safe Return",
  "Ceasefire",
  "Ceremonial Bell",
  "Chain Destruction",
  "Change of Heart",
  "Chimera the Flying Mythical Beast",
  "Chosen One",
  "Cold Wave",
  "Collected Power",
  "Copycat",
  "Crimson Sentry",
  "Cure Mermaid",
  "Curse of the Masked Beast",
  "Cyber Falcon",
  "Cyber-Stein",
  "Cyber-Tech Alligator",
  "Cyclon Laser",
  "Dancing Fairy",
  "Dark Bat",
  "Dark Magic Curtain",
  "Black Magic Ritual",
  "Dark Magician Girl",
  "Dark Necrofear",
  "Dark Spirit of the Silent",
  "Dark Zebra",
  "Dark-Eyes Illusionist",
  "Darkfire Soldier #1",
  "Darkfire Soldier #2",
  "Deal of Phantom",
  "Deepsea Warrior",
  "De-Fusion",
  "Delinquent Duo",
  "Destruction Punch",
  "D.D. Warrior",
  "Dimensionhole",
  "DNA Surgery",
  "Dokuroyaiba",
  "Dragonic Attack",
  "Dreamsprite",
  "Drill Bug",
  "Driving Snow",
  "Dunames Dark Witch",
  "Dust Tornado",
  "Earthbound Spirit",
  "Earthshaker",
  "Ekibyo Drakmord",
  "Electromagnetic Bagworm",
  "Elf's Light",
  "Empress Mantis",
  "Enchanted Javelin",
  "Fairy Box",
  "Fairy Guardian",
  "Fairy Meteor Crush",
  "Fairy's Gift",
  "Fire Princess",
  "Fire Sorcerer",
  "Flame Cerebrus",
  "Flame Champion",
  "Flame Dancer",
  "Flame Swordsman",
  "Flash Assailant",
  "Flying Fish",
  "Flying Kamakiri #1",
  "Flying Kamakiri #2",
  "Forced Requisition",
  "Forest",
  "Fusion Gate",
  "Fusion Sage",
  "Gadget Soldier",
  "Gamble",
  "Gamma the Magnet Warrior",
  "Garnecia Elefantis",
  "Garuda the Wind Spirit",
  "Gazelle the King of Mythical Beasts",
  "Gearfried the Iron Knight",
  "Gemini Elf",
  "Giant Germ",
  "Giant Rat",
  "Giant Trunade",
  "Gift of the Mystical Elf",
  "Gilasaurus",
  "Girochin Kuwagata",
  "Goblin Attack Force",
  "Graceful Dice",
  "Gradius",
  "Grand Tiki Elder",
  "Graverobber",
  "Graverobber's Retribution",
  "Gravity Bind",
  "Greenkappa",
  "Ground Attacker Bugroth",
  "Ground Collapse",
  "Gryphon Wing",
  "Gust",
  "Gust Fan",
  "Sky Scout", // "Harpie's Brother",
  "Harpie's Feather Duster",
  "Harpie's Pet Dragon",
  "Hayabusa Knight",
  "Headless Knight",
  "Humanoid Slime",
  "Humanoid Worm Drake",
  "Hysteric Fairy",
  "Imperial Order",
  "Infinite Cards",
  "Infinite Dismissal",
  "Insect Barrier",
  "Insect Imitation",
  "Insect Queen",
  "Insect Soldiers of the Sky",
  "Inspection",
  "Island Turtle",
  "Jam Breeding Machine",
  "Jam Defender",
  "Jar of Greed",
  "Jinzo",
  "Jowgen the Spiritualist",
  "Kaminari Attack",
  "Karate Man",
  "Cipher Soldier", //"Kinetic Soldier",
  "Kiseitai",
  "Kotodama",
  "Kunai with Chain",
  "Kycoo the Ghost Destroyer",
  "Lady Assailant of Flames",
  "Lady Panther",
  "Last Will",
  "Leogun",
  "Light of Intervention",
  "Lightforce Sword",
  "Lightning Blade",
  "Lightning Conger",
  "Limiter Removal",
  "Liquid Beast",
  "Lord of D.",
  "Machine King",
  "Mad Sword Beast",
  "Mage Power",
  "Magic Cylinder",
  "Magic Drain",
  "Magic Jammer",
  "Magical Thorn", // "Magic Thorn",
  "Magical Hats",
  "Magical Labyrinth",
  "Magical Arm Shield", //"Magic-Arm Shield",
  "Magician of Black Chaos",
  "Major Riot",
  "Makiu, the Magical Mist", // "Makiu",
  "Manga Ryu-Ran",
  "Darklord Marie", // "Marie the Fallen One",
  "Maryokutai",
  "Mask of Brutality",
  "Mask of Dispel",
  "Mask of Restrict",
  "Mask of the Accursed",
  "Mask of Weakness",
  "Mega Thunderball",
  "Melchid the Four-Face Beast",
  "Messenger of Peace",
  "Metal Detector",
  "Metalmorph",
  "Michizure",
  "Minor Goblin Official",
  "Miracle Dig",
  "Mirror Wall",
  "Monster Reborn",
  "Morinphen",
  "Morphing Jar #2",
  "Mother Grizzly",
  "Mountain",
  "Mr. Volcano",
  "Muka Muka",
  "Multiply",
  "Mysterious Puppeteer",
  "Mystic Box",
  "Mystic Lamp",
  "Mystic Probe",
  "Mystic Tomato",
  "Mystical Space Typhoon",
  "Nightmare's Steelcage",
  "Nimble Momonga",
  "Nobleman of Crossout",
  "Nobleman of Extermination",
  "Numinous Healer",
  "Nuvia the Wicked",
  "Octoberser",
  "Ocubeam",
  "Offerings to the Doomed",
  "Oni Tank T-34",
  "Overdrive",
  "Panther Warrior",
  "Parasite Paracide",
  "Patrol Robo",
  "Penguin Soldier",
  "Perfectly Ultimate Great Moth",
  "Polymerization",
  "Premature Burial",
  "Prohibition",
  "Psychic Kappa",
  "Raimei",
  "Rain of Mercy",
  "Red Archery Girl",
  "Red-Eyes Black Dragon",
  "Red-Eyes Black Metal Dragon",
  "Vampire Baby", // "Red-Moon Baby",
  "Respect Play",
  "Return of the Doomed",
  "Revival Jam",
  "Rigorous Reaver", //"Rigras Leever",
  "Riryoku",
  "Riryoku Field",
  "Rocket Warrior",
  "Royal Command",
  "Salamandra",
  "Sangan",
  "Scapegoat",
  "Science Soldier",
  "Scroll of Bewitchment",
  "Seal of the Ancients",
  "Sebek's Blessing",
  "Shadow of Eyes",
  "Shield & Sword",
  "Shift",
  "Shining Abyss",
  "Shining Angel",
  "Sinister Serpent",
  "Skelengel",
  "Skull Dice",
  "Skull Invitation",
  "Skull Lair",
  "Skull Mariner",
  "Slate Warrior",
  "Slot Machine",
  "Snatch Steal",
  "Sogen",
  "Solemn Wishes",
  "Solomon's Lawbook",
  "Sonic Bird",
  "Soul of Purity and Light",
  "Soul Release",
  "Souls of the Forgotten",
  "Spear Cretin",
  "Spherous Lady",
  "Spikebot",
  "Spirit Elimination",
  "Spirit of Flames",
  "Spirit of the Breeze",
  "Spiritualism",
  "St. Joan",
  "Steel Ogre Grotto #2",
  "Steel Shell",
  "Stone Ogre Grotto",
  "Summoner of Illusions",
  "Supply",
  "Sword Hunter",
  "Sword of Dragon's Soul",
  "Swordsman of Landstar",
  "The All-Seeing White Tiger",
  "The Dark Door",
  "The Earl of Demise",
  "The Emperor's Holiday",
  "The Eye of Truth",
  "The Flute of Summoning Dragon",
  "The Forgiving Maiden",
  "The Gross Ghost of Fled Dreams",
  "The Immortal of Thunder",
  "The Last Warrior from Another Planet",
  "The Legendary Fisherman",
  "The Masked Beast",
  "The Portrait's Secret",
  "The Regulation of Tribe",
  "The Rock Spirit",
  "The Shallow Grave",
  "The Statue of Easter Island",
  "The Unfriendly Amazon",
  "The Wicked Worm Beast",
  "Thousand Dragon",
  "Thousand Knives",
  "Three-Headed Geedo",
  "Tiger Axe",
  "Time Machine",
  "Time Seal",
  "Time Wizard",
  "Toon Mermaid",
  "Toon Summoned Skull",
  "Toon World",
  "Tornado Bird",
  "Tornado Wall",
  "Torrential Tribute",
  "Total Defense Shogun",
  "Tribute to the Doomed",
  "Turtle Bird",
  "Twin-Headed Fire Dragon",
  "Type Zero Magic Crusher",
  "UFO Turtle",
  "Ultimate Offering",
  "Umi",
  "United We Stand",
  "Valkyrion the Magna Warrior",
  "Vengeful Bog Spirit",
  "Wall Shadow",
  "Wasteland",
  "Windstorm of Etaqua",
  "Wingweaver",
  "Witch of the Black Forest",
  "World Suppression",
  "Worm Drake",
  "Wow Warrior",
  "Yami",
  "Zombyra the Dark",
];
