import {
  cafeHoppingPreview,
  caroPreview,
  challengePreview,
  createYugiohCardPreview,
  cryptoPreview,
  dairyFarmsPreview,
  deadlinePreview,
  downloadYoutubePreview,
  duyenPreview,
  extractSubtitlePreview,
  klinhPreview,
  lotoPreview,
  memoriesCubePreview,
  memoriesWallPreview,
  paparazziPreview,
  planPreview,
  pricingPlanPreview,
  tripChecklistPreview,
  uploadsPreview,
  urlRandomCardPreview,
  urlShortenerPreview,
  websiteTemplatePreview,
  workplanPreview,
  workPlayPreview,
  xomlayshopPreview,
} from "../assets/images";

const countDownList = [
  // {
  //   title: "Tứng Anh Deadline Countdown",
  //   image: deadlinePreview,
  //   route: "/tuan-anh-countdown",
  //   url: "",
  //   info: "Có gì dzui đâu mà đòi tham gia - Tứng Anh said :))",
  //   deadlineDate: "2022-06-30",
  //   pageTitleName: "TUẤN ANH",
  // },
  // {
  //   title: "Ruon Deadline Countdown",
  //   image: deadlinePreview,
  //   route: "/ruon-countdown",
  //   url: "",
  //   info: "Chắc zui :)) - Ruon said :))",
  //   deadlineDate: "2022-11-13",
  //   pageTitleName: "RUON",
  // },
];

export const stackList = [
  // {
  //   id: 1,
  //   title: "Mini Games",
  //   icon: "game-controller-b",
  //   list: [
  //     {
  //       id: 1,
  //       title: "Caro",
  //       image: caroPreview,
  //       route: "/play-caro",
  //       url: "",
  //     },
  //     {
  //       id: 2,
  //       title: "Dairy Farms",
  //       image: dairyFarmsPreview,
  //       route: "",
  //       url: "https://dairy-farms.codeq.dev/",
  //     },
  //     {
  //       id: 3,
  //       title: "Bingo Online",
  //       image: lotoPreview,
  //       route: "/play-loto",
  //       url: "",
  //     },
  //     {
  //       id: 4,
  //       title: "Paparazzi",
  //       image: paparazziPreview,
  //       route: "",
  //       url: "https://paparazzi.codeq.dev/",
  //     },
  //   ],
  // },
  {
    id: 1,
    title: "Close Project",
    icon: "game-controller-b",
    list: [
      {
        id: 1,
        title: "Caro",
        image: caroPreview,
        route: "/play-caro",
        url: "",
        isClose: true,
        description: "Tạo game caro chơi 2 người.",
      },
      {
        id: 2,
        title: "Dairy Farms",
        image: dairyFarmsPreview,
        route: "",
        url: "https://dairy-farms.codeq.dev/",
        isClose: true,
        description: "Game pixel đầu tiên muốn clone.",
      },
      {
        id: 3,
        title: "Bingo Online",
        image: lotoPreview,
        route: "/play-loto",
        url: "",
        isClose: true,
        description: "Chơi lô tô online nhiều người.",
      },
      {
        id: 4,
        title: "Paparazzi",
        image: paparazziPreview,
        route: "",
        url: "https://paparazzi.codeq.dev/",
        isClose: true,
        description: "Clone game `what we become what we behold`.",
      },
      {
        title: "Download Youtube",
        image: downloadYoutubePreview,
        route: "",
        url: "https://download.codeq.dev/",
        isClose: true,
        description: "Download youtube video, bị lỗi do youtube đổi API.",
      },
      {
        id: 1,
        title: "Cafe Hopping",
        image: cafeHoppingPreview,
        route: "",
        url: "https://cafehopping.codeq.dev/",
        isClose: true,
        description: "Đóng project do một số quán cà phê đã đóng cửa.",
      },
      {
        title: "Random Card",
        image: urlRandomCardPreview,
        route: "",
        url: "https://randomcard.codeq.dev/",
        isClose: true,
        description: "Hiển thị 1 card random trong danh sách.",
      },
      {
        title: "Gamiquiz",
        image: urlRandomCardPreview,
        route: "",
        url: "https://gamiquiz.codeq.dev/",
        datetime: "2024-09-25",
        isClose: true,
        time: "3h",
        description:
          "Quiz game yugi the destiny. game làm trong 3h do cao hứng khi có một đàn em muốn hỏi tip đánh yugi the destiny.",
      },
      {
        title: "Website Template",
        image: websiteTemplatePreview,
        route: "",
        url: "https://codeq.dev/templates",
        webs: [
          "food-lover.codeq.dev",
          "food-yummy.codeq.dev",
          "burger.codeq.dev",
          "grilli.codeq.dev",
          "madang.codeq.dev",
          "feane.codeq.dev",
          "istanbul.codeq.dev",
          "panakeia.codeq.dev",
          "foodie.codeq.dev",
          "jiniya.codeq.dev",
          "trattoria.codeq.dev",
        ],
        isClose: true,
        description: "Danh sách template website",
      },
      {
        id: 3,
        title: "Pricing Plan",
        image: pricingPlanPreview,
        route: "",
        url: "https://codeq.dev/plans",
        isClose: true,
        description: "Danh sách plan",
      },
      {
        title: "Klinh Birthday",
        image: klinhPreview,
        route: "",
        url: "https://klinh.codeq.dev/",
        datetime: "2022-11-11",
        isClose: true,
        description: "Website sinh nhật klinh",
      },
      {
        title: "Duyen Birthday",
        image: duyenPreview,
        route: "",
        url: "https://duyen.codeq.dev/",
        datetime: "2022-10-17",
        isClose: true,
        description: "Website sinh nhật Tuyền Dinh",
      },
      {
        title: "Xomlayshop",
        image: xomlayshopPreview,
        route: "",
        url: "https://xomlayshop.codeq.dev/",
        datetime: "2023-01-23",
        isClose: true,
        description: "Website rao bán Tuấn Anh",
      },
      {
        title: "Memories Wall",
        image: memoriesWallPreview,
        route: "",
        url: "https://wall-memories.codeq.dev/",
        datetime: "2022-10-17",
        isClose: true,
        description: "Bức tường kỷ niệm nhóm Thingking",
      },
      {
        title: "Create Yugioh Card",
        image: createYugiohCardPreview,
        route: "/create-yugioh-card",
        url: "",
        isClose: true,
        description: "Lỗi thư viện",
      },
      {
        title: "Memories Cube",
        image: memoriesCubePreview,
        route: "",
        url: "https://cube-memories.codeq.dev/",
        datetime: "2024-02-07",
        isClose: true,
        description: "Rubik kỷ niệm Thingking",
      },
      {
        title: "Trip Checklist",
        image: tripChecklistPreview,
        route: "/trip-checklist",
        url: "",
        isClose: true,
        description: "Lỗi thư viện",
      },
    ],
  },
  {
    id: 2,
    type: "count-down",
    title: "Work",
    icon: "work",
    list: countDownList,
  },
  {
    id: 3,
    title: "Application",
    icon: "work",
    list: [
      {
        title: "Word Play",
        image: workPlayPreview,
        route: "/word-play",
        url: "",
      },
      {
        title: "Extract Subtitle",
        image: extractSubtitlePreview,
        route: "",
        url: "https://autosub.codeq.dev/",
      },
      {
        title: "URL Shortener",
        image: urlShortenerPreview,
        route: "",
        url: "https://s.codeq.dev/",
      },

      {
        title: "Upload File",
        image: uploadsPreview,
        route: "/uploads",
        url: "",
      },
      {
        title: "Yugi List Card",
        image: createYugiohCardPreview,
        route: "/jtp",
        url: "",
      },
      {
        title: "Yugi List Card",
        image: createYugiohCardPreview,
        route: "/jtprandom",
        url: "",
      },
    ],
  },
  {
    id: 6,
    title: "Plan",
    icon: "work",
    list: [
      {
        title: "Challenge",
        image: challengePreview,
        route: "/challenge-list",
        url: "",
      },
      {
        title: "Plan Board",
        image: planPreview,
        route: "/plan",
        url: "",
        // datetime: "2024-02-07",
      },
      {
        title: "Work Plan",
        image: workplanPreview,
        route: "",
        url: "https://workplan.codeq.dev/",
        datetime: "2024-07-28",
      },
    ],
  },
  // {
  //   id: 2,
  //   title: "Crypto",
  //   icon: "crypto",
  //   list: [
  //     {
  //       id: 1,
  //       title: "Crypto Bet",
  //       image: cryptoPreview,
  //       route: "/crypto-bet",
  //       url: "",
  //     },
  //   ],
  // },
];
