import { createListImage, createLiteCard } from "../services/ydc.service";
import { mergeCardList } from "./jtp.constants";

export const MAIN_CARDS = createLiteCard(
  mergeCardList.filter((c) => !c.fusion)
);
export const FUSIONS = createLiteCard(mergeCardList.filter((c) => c.fusion));
export const LIST_CARD_IMAGE = createListImage(mergeCardList);

export const MONSTER_CARDS = createLiteCard(
  mergeCardList.filter((c) => c.normal)
);
