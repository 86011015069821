import _ from "lodash";
import numeral from "numeral";
import BigNumber from "bignumber.js";

export const formatNumberDigit = (strNumber, digit = 6) => {
  return Number(BigNumber(strNumber || 0).toFixed(digit));
};

export const formatNumberDigitCurrency = (strNumber, digit = 6) => {
  const formatNumber = formatNumberDigit(strNumber, digit);
  const padStart = "".padStart(digit, "0");
  return numeral(formatNumber).format(`0,0.[${padStart}]`);
};

export function findNearestNumber(arr, target) {
  return arr.reduce((prev, curr) =>
    Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
  );
}

export function swapBytes(hex) {
  hex = (hex || "0").padStart(4, "0"); // Ensure 4-digit format
  return hex.slice(2, 4) + hex.slice(0, 2); // Swap bytes
}

export function hexToDec(hex) {
  // Swap bytes if necessary
  let swappedHex = swapBytes(hex);
  // Convert swapped hex to decimal
  return parseInt(swappedHex, 16);
}

export const decToHexWithSwap = (num) => {
  let hex = num.toString(16).padStart(4, "0").toUpperCase(); // Convert decimal to 4-digit hex
  return swapBytes(hex); // Swap bytes
};
