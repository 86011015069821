import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import CommonButton from "../../components/basic/CommonButton";
import { backCardImage } from "../../constants/jtp.constants";
import FlipCard from "./FlipCard";

export default function ImageCardRandom({ deck, header, flipCount }) {
  // console.log("deck: ", deck);
  // const [isFlipped, setIsFlipped] = useState(false);
  const randomFlipped = false;
  const delayFlip = 100;
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="flex text-3xl font-bold">{header}</div>
      <div
        className="flex flex-row flex-wrap"
        style={{ width: ((100 * 2) / 3) * 10 }}
      >
        {deck?.mainCards?.map((item, key) => {
          return (
            <FlipCard
              key={key}
              frontImage={item?.image}
              backImage={backCardImage}
              flipCount={flipCount}
              randomFlipped={randomFlipped}
              timer={(Number(key) + 1) * delayFlip}
              description={item?.description}
              // flipBack={},
              // setFlipBack,
            />
          );
        })}
      </div>
      <div
        className="flex flex-row flex-wrap mt-2"
        style={{ width: ((100 * 2) / 3) * 10 }}
      >
        {deck.secondCards.map((item, key) => (
          <FlipCard
            key={key}
            frontImage={item?.image}
            backImage={backCardImage}
            flipCount={flipCount}
            randomFlipped={randomFlipped}
            timer={39 * delayFlip + (Number(key) + 1) * delayFlip}
          />
        ))}
      </div>
      {/* <div className="flex mt-1">Extra Deck</div> */}
      {/* <div
        className="flex flex-row flex-wrap mt-2"
        style={{ width: ((100 * 2) / 3) * 10 }}
      >
        {deck.extraCards.map((item, key) => (
          <FlipCard
            key={key}
            frontImage={item?.image}
            backImage={backCardImage}
            flipCount={flipCount}
            randomFlipped={randomFlipped}
          />
        ))}
      </div> */}
    </div>
  );
}
